<template>
  <div
    class="auth-layout-wrap"
  >
    <div class="auth-content">
      <div class="card o-hidden custom-form">
        <div class="row">
          <div class="col-md-12">
            <div class="p-80">
              <div class="text-center">
                <h1 class="mb-3 text-30 font-weight-bold">Verify your Phone Number</h1>
                <p class="text-15">Please enter your OTP code here.</p>
              </div>
              <form action="" class="mt-30">
                <b-form-group label="OTP*" class="text-12">
                  <b-form-input
                    type="text"
                    v-model="otp"
                    required
                    size="lg"
                  ></b-form-input>
                </b-form-group>
                <div class="mt-3 text-center custom-flex">
                  <p>Didn't Receive The OTP?</p>
                  <router-link to="#" tag="a" class="text-muted">
                    <u>Resend OTP?</u>
                  </router-link>
                </div>
                <div class="d-flex justify-content-between">
                  <b-button class="btn btn-block mt-30" size="lg">
                    Cancel
                  </b-button>
                  <b-button variant="primary" class="btn btn-block mt-30 ml-30" size="lg" @click="verifyOtp">
                    Submit
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Verify Password"
  },
  data() {
    return {
      otp: ""
    };
  },
  methods: {
    verifyOtp() {
      this.$router.push("/app/sessions/resetpassword");
    }
  }
};
</script>

<style>
.spinner.sm {
  height: 2em;
  width: 2em;
}
.custom-flex {
  display: flex;
  justify-content: space-between;
}
.auth-layout-wrap:after {
    content: '';
    background-image: url("~@/assets/images/bg/startup-bg.jpeg");
    width: 100%;
    height: 100%;
    position:absolute;
    top:0;
    left:0;
    background-size: cover;
}
</style>
